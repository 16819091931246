import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import DistributionBar from '../DistributionBar';

const ScoreCard = ({ title, score, color, distribution, trend, icon }) => {
  const barColor = color === 'green' ? 'bg-green-500' : 'bg-red-500';
  const textColor = color === 'green' ? 'text-green-500' : 'text-red-500';
  const trendColor = trend > 0 ? 'text-green-500' : 'text-red-500';

  // Convert distribution values to percentages for the DistributionBar component
  const totalResponses = Object.values(distribution).reduce((acc, value) => acc + value, 0);
  const distributionPercentages = {
    veryNegative: (distribution.very_negative / totalResponses) * 100,
    negative: (distribution.negative / totalResponses) * 100,
    neutral: (distribution.neutral / totalResponses) * 100,
    positive: (distribution.positive / totalResponses) * 100,
    veryPositive: (distribution.very_positive / totalResponses) * 100,
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-8 flex flex-col justify-between">
      <div className="flex items-center justify-between mb-6">
        <h3 className={`text-xl font-semibold`}>{title}</h3>
        <div className="text-2xl">{icon}</div>
      </div>
      <div className="flex items-center mb-6">
        <div className="w-full bg-gray-200 rounded-full h-4 mr-4">
          <div
            className={`${barColor} h-4 rounded-full`}
            style={{ width: `${score}%` }}
          ></div>
        </div>
        <span className="text-2xl font-bold">{`${score}`}</span>
      </div>
      <div className="mt-6">
        <DistributionBar
          veryNegative={distributionPercentages.veryNegative}
          negative={distributionPercentages.negative}
          neutral={distributionPercentages.neutral}
          positive={distributionPercentages.positive}
          veryPositive={distributionPercentages.veryPositive}
          isBurnout={title.toLowerCase().includes('burnout')}
        />
      </div>
      <div className="mt-6 flex items-center justify-between">
        <span className="text-sm text-gray-600">Last 30 days</span>
        <div className="flex items-center space-x-2">
          {trend > 0 ? (
            <FaArrowUp className={trendColor} />
          ) : trend < 0 ? (
            <FaArrowDown className={trendColor} />
          ) : null}
          <span className={`text-sm font-semibold ${trendColor}`}>{`${Math.abs(trend)}%`}</span>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
