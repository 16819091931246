import React from 'react';
import { motion } from 'framer-motion';
import { FaTrophy, FaExclamationTriangle } from 'react-icons/fa';
import Loader from '../../utils/Loader';

const InsightTile = ({ title, value, type, subtitle }) => {
  const cardStyles = `rounded-lg p-4 shadow flex flex-col justify-between h-full`;
  const getTypeStyles = () => {
    switch (type) {
      case 'positive':
        return `${cardStyles} bg-green-50 text-green-800 border border-green-200`;
      case 'negative':
        return `${cardStyles} bg-red-50 text-red-800 border border-red-200`;
      default:
        return cardStyles;
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'positive':
        return <FaTrophy className="text-green-500 mr-2" size={20} />;
      case 'negative':
        return <FaExclamationTriangle className="text-red-500 mr-2" size={20} />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      className={getTypeStyles()}
      whileHover={{ scale: 1.02 }}
      layout
      initial={{ opacity: 0.9 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
    >
      <div className="flex items-center mb-2">
        {getIcon(type)}
        <div className="text-sm font-medium">{title}</div>
      </div>
      <div className="text-lg font-bold mb-1">{subtitle}</div>
      <div>
        <div className="text-2xl font-bold mb-1">{value}</div>
      </div>
    </motion.div>
  );
};

const HighlightedInsights = ({ scoreMetadata }) => {
  if (!scoreMetadata || scoreMetadata.length === 0) {
    return <Loader loading={true} size={30} color={"#123abc"} loadingText={"Generating Insights"} />;
  }

  const filteredMetadata = scoreMetadata.filter(item => item.score_type !== "burnoutScore");

  const toFixedSafe = (value, digits = 0) => {
    const num = parseFloat(value);
    return isNaN(num) ? "0" : num.toFixed(digits);
  };

  const distributionPercentage = (item, type) => {
    const { very_positive, positive, neutral, negative, very_negative } = item.distribution;
    const total = very_positive + positive + neutral + negative + very_negative;
    if (total === 0) return 0;
    const percentage = type === "negative" ? ((negative + very_negative) / total) * 100 : ((very_positive + positive) / total) * 100;
    return percentage ? percentage : 0;
  };

  const highestScore = filteredMetadata.reduce((acc, curr) => acc.score > curr.score ? acc : curr);
  const lowestScore = filteredMetadata.reduce((acc, curr) => acc.score < curr.score ? acc : curr);
  const mostPositiveDistribution = filteredMetadata.reduce((acc, curr) => 
    distributionPercentage(acc, "positive") > distributionPercentage(curr, "positive") ? acc : curr);
  const mostConcerningDistribution = filteredMetadata.reduce((acc, curr) => 
    distributionPercentage(acc, "negative") > distributionPercentage(curr, "negative") ? acc : curr);

  const formatTitle = (str) => str.replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/\b\w/g, l => l.toUpperCase());

  const insights = [
    {
      title: "Highest Score",
      value: `${toFixedSafe(highestScore.score)}`,
      subtitle: formatTitle(highestScore.score_type).replace(' Score', ''),
      type: 'positive',
    },
    {
      title: "Lowest Score",
      value: `${toFixedSafe(lowestScore.score)}`,
      subtitle: formatTitle(lowestScore.score_type).replace(' Score', ''),
      type: 'negative',
    },
    {
      title: "Most Positive Responses",
      value: `${toFixedSafe(distributionPercentage(mostPositiveDistribution, "positive"))}%`,
      subtitle: formatTitle(mostPositiveDistribution.score_type).replace(' Score', ''),
      type: 'positive',
    },
    {
      title: "Most Negative Responses",
      value: `${toFixedSafe(distributionPercentage(mostConcerningDistribution, "negative"))}%`,
      subtitle: formatTitle(mostConcerningDistribution.score_type).replace(' Score', ''),
      type: 'negative',
    },
  ];

  return (
    <div className="my-8">
      <h3 className="text-lg font-semibold mb-4">Key Insights</h3>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {insights.map((insight, index) => (
          <InsightTile key={index} {...insight} />
        ))}
      </div>
    </div>
  );
};

export default HighlightedInsights;