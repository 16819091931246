import { Chart } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import TitleCard from "./Cards/TitleCard";

function LineData({ title, dataSet }) {
  const [chartData, setChartData] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "Overall Score",
        data: [0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
    options: {
      spanGaps: true,
    },
  });

  useEffect(() => {
    setChartData({
      labels: dataSet.labels,
      datasets: dataSet.datasets,
    });
  }, [dataSet]);

  return (
    <TitleCard title={title}>
      {chartData ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            spanGaps: true,
            scales: {
              x: {
                display: true,
                grid: {
                  drawBorder: false,
                  color: "rgba(200, 200, 200, 0.3)", // Lighter grid lines
                },
              },
              y: {
                beginAtZero: true,
                max: 100,
                display: true,
                grid: {
                  drawBorder: false,
                  color: "rgba(200, 200, 200, 0.3)", // Lighter grid lines
                },
              },
            },
            elements: {
              line: {
                // tension: 0.4, // Adds slight curve to lines, set to 0 for straight lines
                borderWidth: 2, // Thinner lines
              },
              point: {
                radius: 3, // Smaller point radius
              },
            },
            plugins: {
              title: {
                display: true,
                // text: "Users Gained between 2016-2020"
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </TitleCard>
  );
}

export default LineData;
