import React, { useEffect, useState } from "react";
import { FaRobot, FaInfoCircle, FaUsers, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { mapScoreTypes } from "../../utils/MapFunctions";

const RecommendationItem = ({ recommendation }) => {
  const [expanded, setExpanded] = useState(false);

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "bg-red-500 text-white";
      case "Medium":
        return "bg-yellow-500 text-white";
      case "Low":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden mb-4 cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg border border-blue-100"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-lg font-semibold text-gray-800">
            {recommendation.departments[0]}
          </h5>
          <span
            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${getPriorityColor(
              recommendation.priority
            )}`}
          >
            {recommendation.priority}
          </span>
        </div>
        <p className="text-base text-gray-700 font-medium">
          {recommendation.action}
        </p>
      </div>
      {expanded && (
        <div className="px-6 py-4 bg-gray-50">
          <div className="mb-6">
            <h5 className="text-lg font-semibold mb-2 text-gray-800">
              Recommendation Details
            </h5>
            <div className="bg-blue-50 p-6 rounded-lg shadow-md border-2 border-blue-200">
              <p className="text-base text-gray-700 mb-4 font-medium">
                {recommendation.detail}
              </p>
              <ul className="list-disc list-inside text-gray-600">
                {recommendation.actionSteps.map((step, index) => (
                  <li key={index} className="mb-2">
                    {step}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Key dimensions that influenced this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaInfoCircle className="mr-2 text-blue-500" />
                  Key Dimensions
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.labels.map((label) => (
                <span
                  key={label}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Scope of applicability for this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaUsers className="mr-2 text-blue-500" />
                  Applicable To
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.departments.map((department) => (
                <span
                  key={department}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {department}
                </span>
              ))}
              {recommendation.teams.map((team) => (
                <span
                  key={team}
                  className="inline-block bg-green-100 rounded-full px-3 py-1 text-sm font-semibold text-green-800 mr-2 mb-2"
                >
                  {team}
                </span>
              ))}
              {recommendation.demographics.map((demographic) => (
                <span
                  key={demographic}
                  className="inline-block bg-purple-100 rounded-full px-3 py-1 text-sm font-semibold text-purple-800 mr-2 mb-2"
                >
                  {demographic}
                </span>
              ))}
            </div>
          </div>
          <p className="text-xs text-gray-500 italic">
            *This recommendation is based on insights from experienced HR advisors.
          </p>
        </div>
      )}
    </div>
  );
};

const DimensionGroup = ({ dimension, recommendations }) => {
  const [expanded, setExpanded] = useState(true);

  const sortedRecommendations = recommendations.sort((a, b) => {
    const priorityOrder = { High: 0, Medium: 1, Low: 2 };
    return priorityOrder[a.priority] - priorityOrder[b.priority];
  });

  return (
    <div className="mb-8">
      <div
        className="flex justify-between items-center mb-4 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h3 className="text-2xl font-bold text-gray-800">{dimension}</h3>
        {expanded ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </div>
      {expanded && (
        <div className="space-y-4">
          {sortedRecommendations.map((recommendation, index) => (
            <RecommendationItem
              key={`${recommendation.id}-${index}`}
              recommendation={recommendation}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Recommendations = ({ recommendationsMap }) => {
  const [groupedRecommendations, setGroupedRecommendations] = useState({});

  useEffect(() => {
    if (recommendationsMap && recommendationsMap.length > 0) {
      const recommendationsBuilder = {};

      recommendationsMap.forEach((element) => {
        const department = element.department;
        const departmentRecommendations = element.recommendations;
        departmentRecommendations.forEach((rec) => {
          const labels = rec.labels;
          const action = rec.text;
          const id = rec.scoreType;
          let dimension = rec.type;
          const actionSteps = rec.action_steps;
          const score = rec.score;

          // Remove the "Score" suffix from the dimension name
          if (dimension.endsWith(" Score")) {
            dimension = dimension.slice(0, -6);
          }

          const recommendationObject = {
            priority: score < 30 ? "High" : score <= 40 ? "Medium" : "Low",
            dimension: dimension,
            action: action,
            id: id,
            labels: processLabels(labels),
            departments: [department],
            teams: [department],
            demographics: ["All Genders", "All Locations", "All Ethnicities"],
            confidence: Math.floor(Math.random() * 19) + 82,
            actionSteps: actionSteps,
            detail: rec.detail || "Details not provided.",
          };

          if (!recommendationsBuilder[dimension]) {
            recommendationsBuilder[dimension] = [];
          }
          recommendationsBuilder[dimension].push(recommendationObject);
        });
      });

      setGroupedRecommendations(recommendationsBuilder);
    }
  }, [recommendationsMap]);

  function processLabels(labels) {
    const arr = labels.split(",").map((label) => mapScoreTypes(label.trim()));
    return arr;
  }

  const sortedDimensions = Object.keys(groupedRecommendations).sort((a, b) => a.localeCompare(b));

  return (
    <div className="bg-white rounded-3xl shadow-2xl p-10">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-4xl font-bold text-gray-800">
          Actionable Recommendations
        </h2>
        <FaRobot className="text-4xl text-blue-500" />
      </div>
      <p className="text-gray-700 text-lg font-medium mb-8">
        Tailored strategies to enhance team dynamics and individual satisfaction
        across key dimensions.
      </p>
      <div className="space-y-8">
        {sortedDimensions.map((dimension) => (
          <DimensionGroup
            key={dimension}
            dimension={dimension}
            recommendations={groupedRecommendations[dimension]}
          />
        ))}
      </div>
    </div>
  );
};

export default Recommendations;