import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Select from "react-select";
import LineData from "../LineData";
import "../../index.css";
import Loader from "../../utils/Loader";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
const _ = require("lodash");
Chart.register(CategoryScale);

export default function TimeSeries({ filters, data, teams, handleTeamChange }) {
  const [multiValue, setMultiValue] = useState([]);

  var availableTeams = [];
  if (teams) {
    var teamOptions = teams.map((team) => ({
      value: team,
      label: team,
    }));
    availableTeams = teamOptions;
  }

  const handleMultiChange = (option) => {
    setMultiValue(option);
  };

  const submitTeams = () => {
    handleTeamChange(multiValue.map((value) => value["value"]));
    data = null;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <label className="block text-gray-700 font-bold mb-2">Add Departments</label>
      <div className="mb-4">
        <Select
          name="filters"
          placeholder="Select departments..."
          value={multiValue}
          onChange={handleMultiChange}
          options={availableTeams}
          isMulti
          className="text-base"
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: "#E5E7EB",
              borderRadius: "0.375rem",
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
              "&:hover": {
                borderColor: "#D1D5DB",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#EFF6FF" : "white",
              color: state.isSelected ? "#1E40AF" : "#374151",
              "&:hover": {
                backgroundColor: "#DBEAFE",
                color: "#1E40AF",
              },
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#EFF6FF",
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: "#1E40AF",
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: "#1E40AF",
              "&:hover": {
                backgroundColor: "#DBEAFE",
                color: "#1E40AF",
              },
            }),
          }}
        />
      </div>
      <Button
        variant="contained"
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={submitTeams}
      >
        Add Departments
      </Button>
      <Loader
        loading={!data.scoreTrendDayOverDayData}
        componentLoading={true}
        size={30}
        color={"#3B82F6"}
        loadingText={"Loading Time Series Data"}
      >
        <LineData
          title="Department Sentiment Comparison - Day Over Day"
          dataSet={data.scoreTrendDayOverDayData || []}
        />
        {/* Insert Tables Here */}
        <div className="h-16"></div>
      </Loader>
    </div>
  );
}