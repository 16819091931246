import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, updateDoc, arrayRemove } from "firebase/firestore";
import { auth } from "../../firebase";
import TitleCard from "../Cards/TitleCard";
import PerformerCard from "./PerformerCard";
import PerformerSearchBar from "./PerformerSearchBar";
import Loader from "../../utils/Loader";
import { getSelectedCompanyId } from "../../utils/companyStorage";
import { getFilterDatesConditions } from "../../utils/DateRange";
import { getApiUrl } from "../../utils/apiConfig";

const calculateAverage = (scores) => {
  const validScores = scores.filter(
    (score) => score !== undefined && score !== null && score > 0
  );
  return validScores.length > 0
    ? Math.round(
        validScores.reduce((total, current) => total + current, 0) /
          validScores.length
      )
    : 0;
};

const mapScoresToCategories = (scoreMap) => {
  if (!scoreMap) return {};
  return {
    Wellbeing: calculateAverage([
      scoreMap.psychological_safety_score,
      scoreMap.positivity_score,
      scoreMap.energy_score,
      scoreMap.physical_environment_score,
      scoreMap.burnout_score,
      scoreMap.belonging_score,
    ]),
    Performance: calculateAverage([
      scoreMap.recognition_score,
      scoreMap.productivity_score,
      scoreMap.motivation_score,
      scoreMap.focus_score,
    ]),
    Engagement: calculateAverage([
      scoreMap.collaboration_score,
      scoreMap.engagement_score,
      scoreMap.empowerment_score,
      scoreMap.sentiment_score,
      scoreMap.energy_score,
      scoreMap.communication_score,
    ]),
    Growth: calculateAverage([
      scoreMap.recognition_score,
      scoreMap.autonomy_score,
      scoreMap.career_growth_score,
      scoreMap.personal_satisfaction_score,
    ]),
    Leadership_and_Vision: calculateAverage([
      scoreMap.manager_effectiveness_score,
      scoreMap.alignment_score,
      scoreMap.leadership_sentiment_score,
      scoreMap.confidence_score,
    ]),
  };
};

const hasEnoughData = (dimensionScores, numberOfMessages) => {
  const mainCategories = ['Wellbeing', 'Performance', 'Engagement', 'Growth', 'Leadership_and_Vision'];
  const validScores = mainCategories.filter(category => dimensionScores[category] > 0);
  return validScores.length >= 3 && numberOfMessages > 0;
};

const PerformerView = ({ performerType, filters }) => {
  const [performersData, setPerformersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const companyId = await getSelectedCompanyId(user.uid);
        setCurrentUserUid(companyId);
      } else {
        window.location.href = "/signin";
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUserUid && filters) {
      fetchPerformers(currentUserUid);
    }
  }, [currentUserUid, performerType, filters]);

  const fetchPerformers = async (companyId) => {
    setLoading(true);
    setError(null);
    setPerformersData([]);

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl('get-high-performers');
      let url = new URL(endpointUrl);
      
      url.searchParams.append('company_uuid', companyId);
      url.searchParams.append('performerType', performerType);

      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (value && key !== 'scoreType' && key !== 'dateRange' && key !== 'generation' && key !== 'start_date' && key !== 'end_date') {
            url.searchParams.append(key, value);
          }
        }
        
        const [startDateParam, endDateParam] = getFilterDatesConditions(filters.start_date, filters.end_date);
        if (startDateParam) url.searchParams.append('start_date', startDateParam.split('=')[1]);
        if (endDateParam) url.searchParams.append('end_date', endDateParam.split('=')[1]);
      }

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPerformersData(data || []);
    } catch (error) {
      console.error(`Error fetching ${performerType} data:`, error);
      setError(`Error fetching ${performerType} data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePerformer = async (performerId) => {
    const db = getFirestore();
    const user = auth.currentUser;

    if (!user) {
      console.error("No authenticated user");
      return;
    }

    const companyRef = doc(db, "companies", currentUserUid);

    try {
      await updateDoc(companyRef, {
        [performerType]: arrayRemove(performerId),
      });

      setPerformersData((prevData) =>
        prevData.filter((p) => p.employee_uuid !== performerId)
      );
    } catch (error) {
      console.error("Error removing performer:", error);
    }
  };

  const title =
    performerType === "highPerformers"
      ? "High Performers"
      : "Real-Time Performers";

  return (
    <>
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              This feature is currently in beta.
            </p>
          </div>
        </div>
      </div>

      <TitleCard title={title}>
        <div className="mb-4">
          <PerformerSearchBar
            performerType={performerType}
            onUpdate={() => fetchPerformers(currentUserUid)}
            filters={filters}
          />
        </div>
        <Loader loading={loading} loadingText={`Fetching ${title}`}>
          {error ? (
            <p className="text-center text-red-500 text-xl mt-8">{error}</p>
          ) : performersData.length > 0 ? (
            <div>
              {performersData.map((performer) => {
                const dimensionScores = mapScoresToCategories(performer.scores);
                const hasData = hasEnoughData(dimensionScores, performer.number_of_messages);
                const overallSentiment = hasData
                  ? calculateAverage(Object.values(dimensionScores))
                  : 0;
                return (
                  <PerformerCard
                    performerType={performerType}
                    key={performer.employee_uuid}
                    id={performer.employee_uuid}
                    name={`${performer.first_name} ${performer.last_name}`}
                    team={performer.job_title}
                    department={performer.department}
                    overallSentiment={overallSentiment}
                    dimensionScores={dimensionScores}
                    onRemove={handleRemovePerformer}
                    hasEnoughData={hasData}
                    removeButton={
                      <button
                        className="btn btn-error btn-sm"
                        onClick={() =>
                          handleRemovePerformer(performer.employee_uuid)
                        }
                      >
                        Remove
                      </button>
                    }
                  />
                );
              })}
            </div>
          ) : (
            <p className="text-center text-gray-500 text-xl mt-8">
              No {title} selected. Please use the search bar to add{" "}
              {title.toLowerCase()}.
            </p>
          )}
        </Loader>
      </TitleCard>
    </>
  );
};

export default PerformerView;