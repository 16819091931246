import React from 'react';
import { CircularProgress, Box, Typography, Paper, Link } from '@mui/material';

const DataProcessingStatus = () => {
  return (
    <Paper elevation={3} className="p-6 mb-6 bg-white rounded-lg shadow-md">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress size={48} className="mb-4 text-blue-500" />
        <Typography variant="h6" className="mb-3 text-gray-800 font-semibold">
          Analyzing Your Data
        </Typography>
        <Typography variant="body1" className="mb-4 text-gray-600 text-center">
          This may take up to 72 hours, depending on data volume.
        </Typography>
        <Typography variant="body2" className="text-gray-500 text-center">
          No data visible?{' '}
          <Link href="/integrations" color="primary">
            Check your integrations
          </Link>
        </Typography>
      </Box>
    </Paper>
  );
};

export default DataProcessingStatus;