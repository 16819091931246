import React, { useState, useEffect } from "react";
import Header from "./Header";
import WorksenseLogo from "../images/logos/worksenselogo2.png";
import { auth } from "../firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { BookOpenIcon, BoltIcon, Squares2X2Icon, ChartBarIcon } from "@heroicons/react/24/outline";
import { getSelectedCompanyId, setSelectedCompanyId } from "../utils/companyStorage"; // Adjust the path as necessary
import { onAuthStateChanged } from "firebase/auth";

const Layout = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(getSelectedCompanyId());
  const [isAdminUser, setIsAdminUser] = useState(false); // Add a new state to track if the user is an admin
  const [userData, setUserData] = useState({ displayName: "", email: "" });

  useEffect(() => {
    const fetchCompanies = async () => {
      const db = getFirestore();
      const companiesRef = collection(db, "companies");
      const snapshot = await getDocs(companiesRef);
      const companiesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCompanies(companiesData);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAdminUser(user.uid === "4KISCpZsWBd2xinV7d4M1P9kZFq2"); // Set isAdminUser based on user ID
        setUserData({ displayName: user.displayName, email: user.email });
      } else {
        console.log("No authenticated user");
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);
    setSelectedCompanyId(companyId);
    window.location.href = "/dashboard";
  };

  return (
    <div data-theme="emerald" className="drawer drawer-mobile bg-base-200">
      <input
        id="left-sidebar-drawer"
        type="checkbox"
        className="drawer-toggle"
      />
      <div
        data-theme="emerald"
        className="drawer-content flex flex-col min-h-screen"
      >
        <Header
          data-theme="emerald"
          userDisplayName={userData.displayName}
          userEmail={userData.email}
        />
        <main
          data-theme="emerald"
          className="flex-1 overflow-y-auto pt-8 px-6"
        >
          {children}
        </main>
      </div>
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col"></div>
        <div className="drawer-side z-20">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu pt-2 w-80 bg-base-200 min-h-screen">
            <li className="mb-2 font-semibold text-xl hover:bg-gray-300">
              <a href={"/dashboard"}>
                <img
                  className="mask mask-square w-12"
                  src={WorksenseLogo}
                  alt="Worksense Logo"
                />
                Worksense
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/dashboard">
                <div className="flex">
                  <Squares2X2Icon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Dashboard
                </div>
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/insights">
                <div className="flex">
                  <ChartBarIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Insights
                </div>
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/integrations">
                <div className="flex">
                  <BoltIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Integrations
                </div>
              </a>
            </li>
            <li className="mb-2 font-semibold text-xl">
              <a href="/documentation">
                <div className="flex">
                  <BookOpenIcon className={"h-7 w-7 pr-2 base-100"} />{" "}
                  Documentation
                </div>
              </a>
            </li>
            {isAdminUser && (
              <form className="mb-2 font-semibold text-xl ">
                <label
                  htmlFor="companies"
                  className="block text-center mb-2 font-semibold text-xl"
                >
                  Select User View
                </label>
                <select
                  id="companies"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleCompanyChange}
                  value={selectedCompany}
                >
                  <option value="" disabled>
                    Select a Company
                  </option>
                  {companies
                    .filter((company) => company.email)
                    .map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.email} - {company.id}
                      </option>
                    ))}
                </select>
              </form>
            )}
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Layout;
