import React from "react";
import Loader from "../../utils/Loader";
import ScoreTable from "../ScoreTable";
import HighlightedInsights from "./HighlightedInsights";
import ScoreCard from "./ScoreCard";
import {
  FaSmile,
  FaFireAlt,
} from "react-icons/fa";

export default function Reports({ data, messageScoreAveragesData, filters, isDemoUser = false }) {
  // Ensure filters has a default value
  filters = filters || {};

  const findScoreAndDistribution = (scoreType) => {
    if (!messageScoreAveragesData) return { score: 'N/A', distribution: {} };

    const scoreData = messageScoreAveragesData.find(s => s.score_type === scoreType);
    if (!scoreData) return { score: 'N/A', distribution: {} };

    return {
      score: Math.floor(scoreData.score),
      distribution: scoreData.distribution
    };
  };

  const sentimentData = messageScoreAveragesData ? findScoreAndDistribution("sentimentScore") : { score: '', distribution: {} };
  const burnoutData = messageScoreAveragesData ? findScoreAndDistribution("burnoutScore") : { score: '', distribution: {} };

  const scores = [
    {
      title: "Sentiment",
      score: sentimentData.score,
      color: "green",
      icon: <FaSmile className="text-green-500 text-2xl" />,
      distribution: sentimentData.distribution,
      trend: -0.3,
    },
    {
      title: "Burnout Risk",
      score: burnoutData.score,
      color: "green",
      icon: <FaFireAlt className="text-red-500 text-2xl" />,
      distribution: burnoutData.distribution,
      trend: 3,
    },
  ];

  return (
    <Loader loading={!data} size={50} color={"#123abc"}>
      <div className="p-4">
        <HighlightedInsights scoreMetadata={messageScoreAveragesData} />

        <div className="mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
            {scores.map((score, index) => (
              <ScoreCard
                key={index}
                title={score.title}
                score={score.score}
                color={score.color}
                icon={score.icon}
                trend={score.trend}
                distribution={score.distribution}
              />
            ))}
          </div>
        </div>

        <ScoreTable items={messageScoreAveragesData} />
      </div>
    </Loader>
  );
}
