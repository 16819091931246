import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import InsightsTabs from "../components/InsightsComponents/InsightsTabs";
import Layout from "../containers/Layout";
import { db } from "../firebase";
import "../index.css";
import { checkAuth } from "../utils/AuthCheck";
import * as Constants from "../utils/Constants";
import Loader from "../utils/Loader";
import { getApiUrl } from "../utils/apiConfig";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import { checkSandBoxStatus } from "../utils/checkSandboxStatus";

Chart.register(CategoryScale);

export default function Insights() {
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [recommendationScores, setRecommendationScores] = useState([]);
  const [authComplete, setAuthComplete] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentCompanyUid, setCurrentCompanyUid] = useState(null);
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] =
    useState("");

  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
        setAuthComplete(true);
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    }

    authenticateUser();
  }, []);

  useEffect(() => {
    if (authComplete && user) {
      const fetchSelectedCompanyId = async () => {
        const companyId = await getSelectedCompanyId(user.uid);
        setCurrentCompanyUid(companyId);

        const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        setIsDemoUser(userData?.isDemoUser ?? true);
      };
      fetchSelectedCompanyId();
    }
  }, [authComplete, user]);

  useEffect(() => {
    async function populateRecommendations() {
      if (currentCompanyUid && userToken) {
        const isSandboxUser = checkSandBoxStatus(currentCompanyUid);
        if (isSandboxUser) {
          setSandboxUserQueryParameter("&demo=True");
        }
        const persistedRecommendations = await getRecommendationsMap();
        if (persistedRecommendations) {
          setRecommendationScores(persistedRecommendations);
        } else {
          await getRecommendations();
        }
        setIsLoading(false);
      }
    }
    populateRecommendations();
  }, [currentCompanyUid, userToken, sandboxUserQueryParameter]);

  useEffect(() => {
    async function checkPaymentStatus() {
      if (currentCompanyUid && user) {
        const hasPaid = await validatePayment(user.uid);
        if (!hasPaid) {
          window.location.href = "/onboarding"; // Redirect to beginning of onboarding
        }
      }
    }

    checkPaymentStatus();
  }, [currentCompanyUid, user]);

  useEffect(() => {
    if (isDataFetched) {
      if (recommendationScores.length > 0) {
        persistRecommendationsMap();
      }
    }
  }, [isDataFetched, recommendationScores]);

  async function getRecommendations() {
    try {
      const endpointUrl = getApiUrl("get-recommendation-departments");
      const url = `${endpointUrl}?company_uuid=${currentCompanyUid}${sandboxUserQueryParameter}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        mode: "cors",
      });
      const responseData = await response.json();
      setRecommendationScores(responseData);
      setIsDataFetched(true);
    } catch (error) {
      setRecommendationScores([]);
      console.error("Error fetching recommendations:", error);
    }
  }

  async function persistRecommendationsMap() {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, currentCompanyUid);
    await setDoc(
      userDocRef,
      {
        recommendationScores,
      },
      { merge: true }
    );
  }

  async function getRecommendationsMap() {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, currentCompanyUid);
    return getDoc(userDocRef)
      .then((userDocSnapshot) => {
        return userDocSnapshot.data().recommendationScores;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Generating key actions"}
      >
        <InsightsTabs
          recommendationsMap={recommendationScores ? recommendationScores : {}} isDemoUser={isDemoUser}
        />
      </Loader>
    </Layout>
  );
}
