import React, { useState } from "react";
import Loader from "../../utils/Loader";
import HeatMap from "../HeatMap";
import HeatMapKey from "./HeatMapKey";
import TitleCard from "../Cards/TitleCard";
import { FaSearch } from "react-icons/fa";
import Toggle from 'react-toggle';
import "react-toggle/style.css";

export default function HeatMapView({ data }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [viewMode, setViewMode] = useState('absolute');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to the first page when search term changes
  };

  return (
    <Loader loading={!data} size={50} color={"#123abc"} loadingText={"Generating Heat Map"}>
      <TitleCard title={"Company Heatmap"}>
        <div className="sticky top-0 z-10">
          <div className="flex justify-between items-center p-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FaSearch className="text-gray-500" />
              </div>
              <input
                type="text"
                id="search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 pl-10 p-2.5"
                placeholder="Search departments..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <HeatMapKey viewMode={viewMode} />
          </div>
          <div className="flex items-center space-x-4 mt-4">
            <Toggle
              checked={viewMode === 'differential'}
              onChange={() => setViewMode(viewMode === 'absolute' ? 'differential' : 'absolute')}
              icons={false}
              className="react-toggle-custom"
            />
            <span className="text-gray-700 font-medium">
              {viewMode === 'absolute' ? 'Absolute Scores' : 'Differential compared to company average'}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <HeatMap
            data={data}
            searchTerm={searchTerm}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            viewMode={viewMode}
          />
        </div>
      </TitleCard>
    </Loader>
  );
}
