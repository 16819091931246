import React, { useState } from "react";
import Recommendations from "./Recommendations";
import CostSavings from "./CostSavings";
import PredictiveAnalytics from "./PredictiveAnalytics";
import AnomalyDetection from "./AnomalyDetection";

const InsightsTabs = ({ recommendationsMap, isDemoUser = false }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    {
      title: "Recommendations",
      Component: (props) => (
        <Recommendations {...props} recommendationsMap={recommendationsMap} />
      ),
    },
    { title: "Cost Savings", Component: CostSavings }
  ];

  if (isDemoUser) {
    tabs.push({ title: "Predictive Analytics", Component: PredictiveAnalytics });
    tabs.push({ title: "Anomaly Detection", Component: AnomalyDetection })
  }

  const ActiveComponent = tabs[activeTabIndex].Component;

  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((tab, index) => (
            <li key={index} className="mr-2">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the page from jumping
                  setActiveTabIndex(index);
                }}
                className={
                  index === activeTabIndex
                    ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }
              >
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content p-4">
        {/* Render the active component */}
        <ActiveComponent key={activeTabIndex} />
      </div>
    </>
  );
};

export default InsightsTabs;
